import {bindingHandlers} from 'knockout';

bindingHandlers.customCarousel = {
    init: function(element, valueAccessor) {

        let carouselData = [];

        if (valueAccessor().data && valueAccessor().data.length > 0) {

            carouselData = valueAccessor().data;

            const carouselInView = [1, 2, 3, 4, 5];

            let carouselContainer;

            const container = document.createElement('div');

            // this.el.append(container);
            element.prepend(container);
            container.className = 'custom-carousel-container';

            carouselData.forEach((item, index) => {
                const carouselItem = item.src ? document.createElement('img') : document.createElement('div');

                container.append(carouselItem);

                carouselItem.className = `custom-carousel-item custom-carousel-item-${index + 1}`;
                carouselItem.src = item.src;
                carouselItem.setAttribute('loading', 'lazy');
                carouselItem.setAttribute('data-index', `${index + 1}`);
            });

            const controlItems = element.querySelectorAll('.custom-carousel-control');

            controlItems.forEach((item) => {
                item.addEventListener('click', () => {
                    controlManager(item.getAttribute('data-name'));
                });
            });

            carouselContainer = container;

            const controlManager = (control) => {
                if (control === 'previous') return previous();
                if (control === 'next') return next();
                return;
            };

            const previous = () => {
                // Update order of items in data array to be shown in carousel
                carouselData.unshift(carouselData.pop());

                // Push the first item to the end of the array so that the previous item is front and center
                carouselInView.push(carouselInView.shift());

                // Update the css class for each carousel item in view
                carouselInView.forEach((item, index) => {
                    carouselContainer.children[index].className = `custom-carousel-item custom-carousel-item-${item}`;
                });

                // Using the first 5 items in data array update content of carousel items in view
                carouselData.slice(0, 5).forEach((data, index) => {
                    document.querySelector(`.custom-carousel-item-${index + 1}`).src = data.src;
                });
            };

            const next = () => {
                // Update order of items in data array to be shown in carousel
                carouselData.push(carouselData.shift());

                // Take the last item and add it to the beginning of the array so that the next item is front and center
                carouselInView.unshift(carouselInView.pop());

                // Update the css class for each carousel item in view
                carouselInView.forEach((item, index) => {
                    carouselContainer.children[index].className = `custom-carousel-item custom-carousel-item-${item}`;
                });

                // Using the first 5 items in data array update content of carousel items in view
                carouselData.slice(0, 5).forEach((data, index) => {
                    document.querySelector(`.custom-carousel-item-${index + 1}`).src = data.src;
                });
            };
        }

        return { controlsDescendantBindings: true };
    }
};
