import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class personal_bodily_sample extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.carouselData = ko.observableArray([
            {
                id:0,
                src: './../img/bg-placeholder-xs.jpg'
            },
            {
                id:1,
                src: './../img/bg-placeholder-xs.jpg'
            },
            {
                id:2,
                src: './../img/bg-placeholder-xs.jpg'
            },
            {
                id:3,
                src: './../img/bg-placeholder-xs.jpg'
            },
            {
                id:4,
                src: './../img/bg-placeholder-xs.jpg'
            },
        ]);
    }
}
