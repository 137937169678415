import ko from 'knockout';
import Handler from 'engine/Handler';
import Chart from 'chart.js/auto';


//import 'knockout-mapping';
export default class petition_item extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        /*chart js*/
        const chart_container = document.querySelector('#myChart');
        if (chart_container) {

            (async function() {
                const data1 = [
                    { year: '', count: 10 },
                    { year: '', count: 15 },
                    { year: '', count: 35 },
                    { year: '', count: 20 },
                    { year: '2', count: 35 },
                    { year: '', count: 32 },
                    { year: '', count: 10 },
                    { year: '', count: 2 },
                ];
                const data2 = [
                    { year: '', count: 2 },
                    { year: '', count: 15 },
                    { year: '', count: 23 },
                    { year: '', count: 26 },
                    { year: '2', count: 28 },
                    { year: '', count: 12 },
                    { year: '', count: 3 },
                    { year: '', count: 26 },
                ];

                new Chart(chart_container, {
                        type: 'line',
                        data: {
                            labels: data1.map(row => row.year),
                            datasets: [
                                {
                                    // label: 'Acquisitions by year',
                                    data: data1.map(row => row.count),
                                    tension: .3,
                                    borderColor: '#26B90D',
                                    pointRadius: 0
                                },
                                {
                                    // label: 'Acquisitions by year',
                                    data: data2.map(row => row.count),
                                    tension: .3,
                                    borderColor: '#FF3F3F',
                                    pointRadius: 0
                                }
                            ],
                            // legend: {
                            //     display: false
                            // },
                            // tooltips: {
                            //     callbacks: {
                            //         label: function(tooltipItem) {
                            //             return tooltipItem.yLabel;
                            //         }
                            //     }
                            // }
                        },
                        options: {
                            plugins: {
                                legend: {
                                    display: false
                                }
                            },
                            scales: {
                                x: {
                                    display: false,
                                    ticks: {
                                        display: false
                                    }
                                },
                                y: {
                                    ticks: {
                                        display: false
                                    }
                                }
                            },
                        },
                        tooltips: {
                            mode: false,
                            callbacks: {
                                title: function() {},
                                label: function() {}
                            }
                        },
                    }

                );
                // chart.options.scales['y-axis-density'].ticks.display = false
                // chart.defaults.global.tooltips.enabled = false;

            })();
        }

        this.addition = ko.observable('opinion');
    }
}
