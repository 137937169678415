import constructor_header from 'ComponentsTwigs/constructor_header.twig';
import user from 'ComponentsTwigs/user.twig';
import header from 'ComponentsTwigs/header.twig';
import call_modal from 'ComponentsTwigs/call_modal.twig';
import invitation_modal from 'ComponentsTwigs/invitation_modal.twig';
import settings_modal from 'ComponentsTwigs/settings_modal.twig';
import complain_modal from 'ComponentsTwigs/complain_modal.twig';
import conversation_settings_modal from 'ComponentsTwigs/conversation_settings_modal.twig';
import history_content_modal from 'ComponentsTwigs/history_content_modal.twig';
import contacts_modal from 'ComponentsTwigs/contacts_modal.twig';
import spam_modal from 'ComponentsTwigs/spam_modal.twig';
import call_from_modal from 'ComponentsTwigs/call_from_modal.twig';
import delete_messages_modal from 'ComponentsTwigs/delete_messages_modal.twig';
import add_to_chat from 'ComponentsTwigs/add_to_chat.twig';
import emoji_block from 'ComponentsTwigs/emoji_block.twig';
import wallet_sidebar from 'ComponentsTwigs/wallet_sidebar.twig';
import chat_group_settings_modal from 'ComponentsTwigs/chat_group_settings_modal.twig';
import toast_info from 'ComponentsTwigs/toast_info.twig';
import community_classic_sample from 'ComponentsTwigs/community_classic_sample.twig';
import community_metro_sample from 'ComponentsTwigs/community_metro_sample.twig';
import community_retro_sample from 'ComponentsTwigs/community_retro_sample.twig';
import company_classic_sample from 'ComponentsTwigs/company_classic_sample.twig';
import company_metro_sample from 'ComponentsTwigs/company_metro_sample.twig';
import company_retro_sample from 'ComponentsTwigs/company_retro_sample.twig';
import main_menu from 'ComponentsTwigs/main_menu.twig';
import setting_menu from 'ComponentsTwigs/setting_menu.twig';
import planet_music_player from 'ComponentsTwigs/planet_music_player.twig';


import personal_black_sample from 'ComponentsTwigs/personal_black_sample.twig';
import personal_bodily_sample from 'ComponentsTwigs/personal_bodily_sample.twig';
import personal_orange_sample from 'ComponentsTwigs/personal_orange_sample.twig';
import personal_white_sample from 'ComponentsTwigs/personal_white_sample.twig';





export default { constructor_header, user, header, call_modal, invitation_modal, settings_modal, complain_modal, conversation_settings_modal, history_content_modal, contacts_modal, spam_modal, call_from_modal, emoji_block, delete_messages_modal,wallet_sidebar, add_to_chat, chat_group_settings_modal, toast_info, community_classic_sample, community_metro_sample, community_retro_sample, company_classic_sample, company_metro_sample, company_retro_sample, main_menu, planet_music_player,setting_menu,  personal_black_sample,personal_bodily_sample,personal_orange_sample,personal_white_sample };
