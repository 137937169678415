import home from 'PagesModels/home';
import company_reg from 'PagesModels/company_reg';
import login from 'PagesModels/login';
import reg_account_choose from 'PagesModels/reg_account_choose';
import remember from 'PagesModels/remember';
import user_reg from 'PagesModels/user_reg';
import web_constructor from 'PagesModels/web_constructor';
import web_constructors from 'PagesModels/web_constructors';
import profile from 'PagesModels/profile';
import chat from 'PagesModels/chat';
import group_chat from 'PagesModels/group_chat';
import chat_link_modal from 'PagesModels/chat_link_modal';
import note from 'PagesModels/note';
import notes_deleted from 'PagesModels/notes_deleted';
import notes_remind from 'PagesModels/notes_remind';
import community_layout from 'PagesModels/community_layout';
import community_edit from 'PagesModels/community_edit';
import company_layout from 'PagesModels/company_layout';
import community_page from "PagesModels/community_page";
import my_page from "PagesModels/my_page";
import company_page from "PagesModels/company_page";
import career from "PagesModels/career";
import election from "PagesModels/election";
import event from "PagesModels/event";
import petition_item from "PagesModels/petition_item";
import petitions from "PagesModels/petitions";
import polls from "PagesModels/polls";
import referendum from "PagesModels/referendum";
import sample_party from "PagesModels/sample_party";
import voting from "PagesModels/voting";
import articles_page from "PagesModels/articles_page";
import videos_page from "PagesModels/videos_page";
import my_videos_page from "PagesModels/my_videos_page";
import video_single from "PagesModels/video_single";
import social_main from "PagesModels/social_main";
import bookmarks from "PagesModels/bookmarks";
import playlists_video from "PagesModels/playlists_video";
import playlist_video from "PagesModels/playlist_video";
import music from "PagesModels/music";
import communities from "PagesModels/communities";
import communities_category from "PagesModels/communities_category";
import communities_control from "PagesModels/communities_control";
import music_albums from "PagesModels/music_albums";
import music_playlists from "PagesModels/music_playlists";
import music_recently_listener from "PagesModels/music_recently_listener";
import music_saved from "PagesModels/music_saved";
import profile_setting from "PagesModels/profile_setting";
import profile_verification from "PagesModels/profile_verification";
import profile_security from "PagesModels/profile_security";
import profile_personal from "PagesModels/profile_personal";
import profile_notices from "PagesModels/profile_notices";
import profile_general from "PagesModels/profile_general";
import profile_blacklist from "PagesModels/profile_blacklist";
import profile_private from "PagesModels/profile_private";
import video_clip_search_page from "PagesModels/video_clip_search_page";
import video_clip_main_page from "PagesModels/video_clip_main_page";
import create_video_clip_page from "PagesModels/create_video_clip_page";
import balance_page from "PagesModels/balance_page";
import wallet_page from "PagesModels/wallet_page";
import wallet_cards from "PagesModels/wallet_cards";
import wallet_operation from "PagesModels/wallet_operation";
import statistic_coverage from "PagesModels/statistic_coverage";
import statistic_attendance from "PagesModels/statistic_attendance";
import statistic_activity from "PagesModels/statistic_activity";
import statistic_post from "PagesModels/statistic_post";
import statistic_messages from "PagesModels/statistic_messages";
import statistic_review from "PagesModels/statistic_review";
import statistic_records from "PagesModels/statistic_records";
import statistic_referendum from "PagesModels/statistic_referendum";
import statistic_petition from "PagesModels/statistic_petition";
import statistic_petition_single from "PagesModels/statistic_petition_single";
import statistic_projects from "PagesModels/statistic_projects";
import profile_more from "PagesModels/profile_more";
import companies from "PagesModels/companies";
import notifications from "PagesModels/notifications";



import catalog_products from 'PagesModels/catalog_products';
import product_instance from 'PagesModels/product_instance';
import service_instance from 'PagesModels/service_instance';
import personal_layout from 'PagesModels/personal_layout';
import personal_edit_layout from 'PagesModels/personal_edit_layout';



import 'binding/carousel';
import 'binding/custom-video-player';
import 'binding/custom_chart';

export default { home, company_reg, login, reg_account_choose, remember, user_reg, web_constructor, web_constructors, profile, chat, group_chat, chat_link_modal, note, notes_deleted,notes_remind, community_layout, company_layout, community_edit,my_page, community_page,career,election, event,petition_item,petitions,polls,music,referendum,sample_party,voting,articles_page,videos_page,my_videos_page,social_main,video_single,bookmarks,playlist_video,playlists_video,communities,communities_category,communities_control, music_albums, music_playlists, music_recently_listener, music_saved,profile_verification,profile_setting,profile_security,profile_personal,profile_notices,profile_general,profile_blacklist,profile_private,video_clip_search_page,video_clip_main_page, balance_page,wallet_page,wallet_cards,wallet_operation,create_video_clip_page,statistic_attendance,statistic_activity,statistic_coverage,statistic_post,statistic_messages,statistic_review,statistic_records,statistic_projects,statistic_petition_single,statistic_petition,statistic_referendum,profile_more,company_page,companies,notifications,  catalog_products,product_instance,service_instance,personal_layout,personal_edit_layout};
