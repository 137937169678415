import constructor_header from 'ComponentsModels/constructor_header';
import user from 'ComponentsModels/user';
import header from 'ComponentsModels/header';
import call_modal from 'ComponentsModels/call_modal';
import invitation_modal from 'ComponentsModels/invitation_modal';
import settings_modal from 'ComponentsModels/settings_modal';
import complain_modal from 'ComponentsModels/complain_modal';
import conversation_settings_modal from 'ComponentsModels/conversation_settings_modal';
import history_content_modal from 'ComponentsModels/history_content_modal';
import contacts_modal from 'ComponentsModels/contacts_modal';
import spam_modal from 'ComponentsModels/spam_modal';
import call_from_modal from 'ComponentsModels/call_from_modal';
import delete_messages_modal from 'ComponentsModels/delete_messages_modal';
import add_to_chat from 'ComponentsModels/add_to_chat';
import emoji_block from 'ComponentsModels/emoji_block';
import chat_group_settings_modal from 'ComponentsModels/chat_group_settings_modal';
import toast_info from 'ComponentsModels/toast_info';
import community_classic_sample from 'ComponentsModels/community_classic_sample';
import community_metro_sample from 'ComponentsModels/community_metro_sample';
import community_retro_sample from 'ComponentsModels/community_retro_sample';
import company_classic_sample from 'ComponentsModels/company_classic_sample';
import company_metro_sample from 'ComponentsModels/company_metro_sample';
import company_retro_sample from 'ComponentsModels/company_retro_sample';
import main_menu from 'ComponentsModels/main_menu';
import planet_music_player from 'ComponentsModels/planet_music_player';
import setting_menu from 'ComponentsModels/setting_menu';
import wallet_sidebar from 'ComponentsModels/wallet_sidebar';

import personal_black_sample from 'ComponentsModels/personal_black_sample';
import personal_bodily_sample from 'ComponentsModels/personal_bodily_sample';
import personal_orange_sample from 'ComponentsModels/personal_orange_sample';
import personal_white_sample from 'ComponentsModels/personal_white_sample';


export default { constructor_header, user, header, call_modal, invitation_modal, settings_modal, wallet_sidebar,complain_modal, conversation_settings_modal, history_content_modal, contacts_modal, spam_modal,emoji_block, call_from_modal, delete_messages_modal, add_to_chat, chat_group_settings_modal, toast_info, community_classic_sample, community_metro_sample, community_retro_sample, company_classic_sample, company_metro_sample, company_retro_sample, main_menu, planet_music_player,setting_menu,personal_black_sample,personal_bodily_sample,personal_orange_sample,personal_white_sample };
