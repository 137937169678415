import ko from 'knockout';
import Handler from 'engine/Handler';
import GLightbox from "glightbox";

//import 'knockout-mapping';
export default class company_page extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        this.filter = ko.observable('photo');

        this.isMobileTabType = ko.observable(0);

        setTimeout(()=>{
            GLightbox({
                touchNavigation: false,
                loop: false,
                autoplayVideos: true
            });
        },0);
        const reactionButtons = document.querySelectorAll(".reaction-item_hovered");
        if (reactionButtons) {
            reactionButtons.forEach(button => {
                button.addEventListener("mouseenter", function() {
                    const hiddenImg = this.querySelector('.hover_hidden');
                    const visibleImg = this.querySelector('.hover_visible');

                    hiddenImg.style.display = 'none';
                    visibleImg.style.display = 'block';

                    setTimeout(function() {
                        hiddenImg.style.display = 'block';
                        visibleImg.style.display = 'none';
                    }, 2500);
                });
            });
        }
    }

    toggleMenu() {
        return {
            click: (data, e) => {
                e.target.closest('.planet_layout .float-menu').classList.toggle('is-active');
            }
        };
    }

    chooseSample(name) {
        this.Store.dispatch({type: 'sample/setSampleName', payload: { name }});
        this.Router.navigate('/community_layout');
    }


}
